.bookUploadContainer {
  margin: 1rem 2rem 0 2rem;
}

.dropZonesContainer {
  .customDropZone {
    .dropzone {
      text-align: center;
      padding: 20px;
      border: 2px dashed #363333;
      background-color: #fafafa;
      color: #8a5656;

      .dropZoneText {
        cursor: pointer;
      }
    }

    li {
      background: none;

      .fw-bold {
        cursor: pointer;
      }
    }
  }
}

.bookUploadBody {
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;

  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */

  .row {
    margin: 0 -15px;

    .col-lg-6,
    .col-md-12 {
      padding: 0 15px;

      @media (max-width: 768px) {
        padding: 0 10px;
      }
    }
  }

  .mandatory {
    color: #d9534f;
  }

  select {
    width: 100%;
    padding: 8px;
    transition: border-color 0.3s ease;
    border: none;
    box-shadow: 0.5px 1px 4px grey;

    &:focus {
      outline: none;
      border-color: #5cb85c;
    }

    &.error {
      border-color: #d9534f;
    }
  }

  .errMsg {
    color: #d9534f;
    font-size: 12px;
    margin-top: 5px;
  }

  .company-profile-container {
    margin-top: 20px;
  }
}

.fileStatusTableContainer {
  margin-top: 3rem;
}